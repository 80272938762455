import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Layout,
  SEO,
  PageHero,
  GradientContainer,
  TextWithImage,
  Instagram,
  StartFreeTrial,
  ContactPageForm,
  Map,
} from '../components';
import { useGraphQL } from '../hooks';

function MeetUsPage() {
  const { meetUsHeroImage } = useImages();
  const { marcosPaulo } = useGraphQL();
  return (
    <Layout>
      <SEO title="Meet the nXtLvL Team" />
      <PageHero
        heading="Meet the nXtLvL team"
        image={meetUsHeroImage.childImageSharp.fluid}
      />
      <GradientContainer>
        <div className="relative grid pb-24 gap-y-24">
          <Kez />
          {/* <Rich /> */}
          {/* <Jodie /> */}
          {/* <Gabb /> */}
          {/* <Harry /> */}
          {/* <Jon /> */}
          {/* <Alex /> */}
          <Sean />
          <Francine />
          {/* <Taylah /> */}
          {/* <Luca /> */}
          {/* <Bec /> */}
        </div>
      </GradientContainer>
      <StartFreeTrial image={marcosPaulo.childImageSharp.fluid} />
      <Instagram />
      <ContactPageForm />
      <Map />
    </Layout>
  );
}

function Kez() {
  const { kez } = useImages();
  return (
    <TextWithImage
      heading="Kez"
      image={kez.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <ul>
        <li>Functional Fitness</li>
        <li>Sports Massage</li>
        <li>Australian Weightlifting Federation Level 1</li>
        <li>CrossFit Level 1</li>
        <li>
          Australian Weightlifting Federation Level 2 - State Sports Power Coach
          (Olympic Lifting)
        </li>
        <li>
          Australian Weightlifting Federation Level 3 - AWF National
          Weightlifting Coach Intern Licence
        </li>
      </ul>
    </TextWithImage>
  );
}

function Rich() {
  const { rich } = useImages();
  return (
    <TextWithImage
      heading="Rich"
      image={rich.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
    >
      <ul>
        <li>Functional Fitness</li>
        <li>Australian Weightlifting Federation Level 1</li>
        <li>CrossFit Level 1</li>
        <li>
          Australian Weightlifting Federation Level 2 - State Sports Power Coach
          (Olympic Lifting)
        </li>
      </ul>
    </TextWithImage>
  );
}

function Jodie() {
  const { jodieLea } = useImages();
  return (
    <TextWithImage
      heading="Jodie"
      image={jodieLea.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <ul>
        <li>Australian Weightlifting Federation Level 1 &amp; 2</li>
        <li>CrossFit Level 1 &amp; 2</li>
        <li>Functional Fitness</li>
        <li>Rowing</li>
      </ul>
    </TextWithImage>
  );
}

function Bec() {
  const { bec } = useImages();
  return (
    <TextWithImage
      heading="Bec"
      image={bec.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
    >
      <ul>
        <li>Yoga</li>
      </ul>
    </TextWithImage>
  );
}
function Gabb() {
  const { gabb } = useImages();
  return (
    <TextWithImage
      heading="Gab"
      image={gabb.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
    >
      <ul>
        <li>Power Lifting</li>
        <li>CrossFit Level 2/Programming/Lesson Planning</li>
        <li>Australian Weightlifting Federation Level 1</li>
      </ul>
    </TextWithImage>
  );
}
function Francine() {
  const { francine } = useImages();
  return (
    <TextWithImage
      heading="Francine"
      image={francine.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      {' '}
      <a href="mailto:f.nunnaritcm@gmail.com">f.nunnaritcm@gmail.com</a>
      <br />
      <a href="tel:0466 110 561">0466 110 561</a>
      <br />
      <ul>
        <li>
          Chinese Medicine Physician (BA Applied Science Traditional Chinese
          Medicine)
        </li>
        <li>
          Diplomate in Canonical Chinese Medicine (DCCM) with Institute of
          Classics in East Asian Medicine - ICEAM)
        </li>
        <li>
          Breathwork Facilitator (Breathless Instructor Training, Master’s
          Instructor Training)
        </li>
        <li>
          Diploma Remedial Massage, including Relaxation and Aromatherapy (NSW
          School of Massage)
        </li>
        <li>Acupuncture</li>
        <li>Myofasioal Cupping</li>
        <li>
          Herbal Consult, Dietary Consul (chinese dietetics), lifestyle consult
        </li>
        <li>Abdominal diagnosis & abdominal massage</li>
      </ul>
    </TextWithImage>
  );
}

function Taylah() {
  const { taylah } = useImages();
  return (
    <TextWithImage
      heading="Taylah"
      image={taylah.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <ul>
        <li>Australian Weightlifting Federation Level 1</li>
      </ul>
    </TextWithImage>
  );
}

function Sean() {
  const { sean } = useImages();
  return (
    <TextWithImage
      heading="Sean"
      image={sean.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
    >
      <ul>
        <li>Functional Fitness</li>
        <li>Boxing & MMA</li>
        <li>AWF (Australian Weightlifting Federation) Level 1 Coach</li>
      </ul>
    </TextWithImage>
  );
}
function Luca() {
  const { luca } = useImages();
  return (
    <TextWithImage
      heading="Luca"
      image={luca.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <ul>
        <li>Calisthenics</li>
      </ul>
    </TextWithImage>
  );
}

function Jon() {
  const { jon } = useImages();
  return (
    <TextWithImage
      heading="Jon"
      image={jon.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <ul>
        <li>Precision Nutrition Level 1</li>
      </ul>
    </TextWithImage>
  );
}

function Harry() {
  const { harry } = useImages();
  return (
    <TextWithImage
      heading="Harry"
      image={harry.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
    >
      <ul>
        <li>Calisthenics</li>
      </ul>
    </TextWithImage>
  );
}
function Alex() {
  const { alex } = useImages();
  return (
    <TextWithImage
      heading="Alex"
      image={alex.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
    >
      <p>On Point Performance Physiotherapy</p>
      <a href="mailto:opphysiotherapy@gmail.com">opphysiotherapy@gmail.com</a>
      <br />
      <a href="tel:0402 518 993">0402 518 993</a>
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/onpoint_performance/?hl=en"
      >
        @on_point_performance
      </a>
      <ul>
        <li>Bachelor of Physiotherapy (2016)</li>
        <li>Dry needling & acupuncture</li>
        <li>Soft tissue massage including instrument assisted</li>
        <li>Sports & kinesio taping</li>
        <li>Cupping</li>
      </ul>
    </TextWithImage>
  );
}

export function useImages() {
  const data = useStaticQuery(
    graphql`
      {
        harry: file(relativePath: { eq: "harry.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alex: file(relativePath: { eq: "alex.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sean: file(relativePath: { eq: "sean-new.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jodieLea: file(relativePath: { eq: "jodie-lea.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        kez: file(relativePath: { eq: "kez-randall2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        meetUsHeroImage: file(relativePath: { eq: "meet-us.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bec: file(relativePath: { eq: "bec.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gabb: file(relativePath: { eq: "gabb.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        francine: file(relativePath: { eq: "francine.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        taylah: file(relativePath: { eq: "taylah.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        luca: file(relativePath: { eq: "luca.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jon: file(relativePath: { eq: "jon.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rich: file(relativePath: { eq: "rich.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return data;
}

export default MeetUsPage;
